(function() {
	'use strict';

	// SLIDER
	if ($('.slider-sliderSliNotiSecSm').length > 0) {
		var sliderInfNotiSecMed = tns({
			"container": ".slider-sliderSliNotiSecSm",
			"controlsContainer": ".slider-sliderSliNotiSecSm-controls",
			"preventScrollOnTouch": "force",
			"controlsPosition": "bottom",
			"controls": false,
			"navPosition": "bottom",
			"mouseDrag": true,
			"speed": 400,
			"center": false,
			"slideBy": 1,
			"fixedWidth": 250,
			"gutter": 16,
			"loop": false,
			"responsive": {
				"768": {
					"slideBy": 1,
					"controls": false,
					"fixedWidth": 316,
					"gutter": 32
				},
				"1280": {
					"slideBy": 1,
					"controls": true,
					"fixedWidth": 316,
					"gutter": 64
				},
				"1600": {
					"slideBy": 1,
					"controls": true,
					"fixedWidth": 316,
					"gutter": 64
				}
			}
		});
	}

})();
